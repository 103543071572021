import { BasicInputProps, INPUT_TYPE } from '@/components/basic-input';
import SelectCountry from '@/components/select-country';
import { SelectItem } from '@/components/select-country/types';
import { useRouter } from '@/core/hooks';
import { LANG } from '@/core/i18n/src/page-lang';
import { useEffect, useState } from 'react';
import css from 'styled-jsx/css';
import { BasicInput } from '../../basic-input';
import { store } from '../store';
type BasicInputPropsOptional = Partial<Pick<BasicInputProps, 'type' | 'label'>> &
  Omit<BasicInputProps, 'type' | 'label'>;

export const InputPhone = (props: BasicInputPropsOptional) => {
  const [val, setVal] = useState('');
  const { label, type, placeholder, ...rest } = props;
  const { isRtl } = useRouter();
  const onChange = (value: string) => {
    setVal(value);
  };
  const onCountrySelect = (item: SelectItem) => {
    store.countryCode = item.countryCode;
    store.countryId = item.id;
  };
  useEffect(() => {
    store.phone = val?.replace(/^0*/, '');
  }, [val]);
  return (
    <div className='input-phone-container'>
      <p className='label'>{LANG('选择国家')}</p>

      <div className='input-row'>
        <div className='select-country-container'>
          <SelectCountry small className='select-country' onChange={onCountrySelect} />
        </div>
        <BasicInput
          label={''}
          type={type || INPUT_TYPE.PHONE}
          value={val}
          {...rest}
          className='phone-input'
          style={isRtl ? { direction: 'rtl' } : { direction: 'ltr' }}
          placeholder={placeholder || LANG('请输入手机号码')}
          onInputChange={onChange}
        />
      </div>
      <style jsx>{styles}</style>
    </div>
  );
};
const styles = css`
  .input-phone-container {
    margin-bottom: 20px;
    .label {
      font-size: 14px;
      font-weight: 400;
      color: var(--spec-font-color-1);
      margin-bottom: 8px;
    }
    .input-row {
      display: flex;
      height: 46px;
      :global(.select-country-container) {
        background-color: var(--spec-background-color-3);
        padding: 5px 12px;
        border-radius: 4px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        border: 1px solid transparent;
        &:hover {
          border: 1px solid var(--skin-primary-color);
        }
        :global(.select-country) {
          position: relative;
          :global(.emulate-select-selected) {
            color: var(--spec-font-color-1);
          }
          :global(.basic-input) {
            padding-left: 10px;
          }
        }
      }
      :global(.phone-input) {
        margin-bottom: 30px;
      }
    }
  }
`;
